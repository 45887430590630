html,
body,
#app,
#app > div,
#app > div > div {
  height: 100%;
  font-family: sans-serif;
}

body{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a {
  text-decoration: none;
}

input:focus,
textarea:focus {
  outline: none;
}

@font-face {
  font-family: latoBlack;
  src: url('fonts/lato-black.ttf');
}
@font-face {
  font-family: latoThin;
  src: url('fonts/lato-thin.ttf');
}

@keyframes pulsatingOpacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulsatingBackground {
  0% {
    background-color: rgba(27, 20, 71, 0);
  }
  50% {
    background-color: rgba(27, 20, 71, 0.5);;
  }
  100% {
    background-color: rgba(27, 20, 71, 0);;
  }
}
